<template>
  <div>
    <div class="text-h5 font-weight-bold">
      {{ resource.dishResource.name }}
    </div>
    <div class="d-flex mt-1">
      <v-chip v-for="tag in resource.resourceTags" :key="tag.id" :color="tag.color" class="mr-1" label small>
        {{ tag.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResourceNameDisplay",
  props: {
    resource: {}
  }
}
</script>

<style scoped>

</style>
