<template>
  <v-card style="grid-auto-rows: 72px;" elevation="0"
          class="keyboard">
    <template v-for="(i,index) in keys">
      <v-btn :color="colors[index]" :key="'key'+i+index" @click="input(i)"
             class="key"
             elevation="0"
             style="height: 100%"
      >
        <v-icon x-large v-if="i.startsWith('mdi')">{{i}}</v-icon>
        <template v-else><span style="font-size: 36px">{{ i }}</span></template>
      </v-btn>
    </template>
  </v-card>

</template>

<script>
export default {
  name: 'KeyBoard',
  props: {
    keys: {
      type: Array
    },
    colors:{}
  },
  methods: {
    input (key) {
      this.$emit('input', key)
    }
  }
}
</script>

<style scoped>
.keyboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
}

.key {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  height: auto;
  border-radius: 5px;
}
</style>
