import {loadBaseConfig} from 'aaden-base-model/lib/Models/GlobalSettings'
import {LocalSettingManager} from 'biewangle'
import hillo from "hillo";
import i18n from "@/i18n";

const defaultConfig = require('@/assets/AadenConfig.json')
let GlobalConfig = Object.assign({}, defaultConfig)

export let NeededKeys = []

export async function loadConfig () {
  try {
    const fix = require('@/assets/FixedConfig.json')
    GlobalConfig = Object.assign(GlobalConfig, await loadBaseConfig(defaultConfig), fix)
    NeededKeys = GlobalConfig.neededKeys
    window.Config = GlobalConfig
  } catch (e) {
    console.log(e)
  }
}

export const Remember = LocalSettingManager.config({
  timeRange: 7
})
export default GlobalConfig

export function decodeDeviceId (str) {
  return pow(BigInt(str), 7n) % 998299n
}

function pow (a, b) {
  let res = a
  for (let i = 1; i < b; i++) {
    res = res * a
  }
  return res
}

export async function getInfoForDeviceId (deviceId) {
  const { url } = await getBaseAndUrlForDeviceId(deviceId)
  try {
    return (await hillo.get(url + '/PHP/Restaurant.php?op=view')).content[0]
  } catch (e) {
    // return {name: "店内主机未连接网络", adress1: "无法读取该店铺数据" + counter++}
    return {
      name: i18n.t('Shop_net_error'),
      adress1: i18n.t('cant_get_data') + ':' + deviceId,
      broken: true,
    }
  }
}

export async function getBaseAndUrlForDeviceId (deviceId) {
  console.log(deviceId, 'change to')
  const url = (await findDeviceByDeviceId(deviceId))
      ?.baseUrl ?? getNgrokUrl(deviceId)
  return {
    deviceId,
    url,
  }
}

export async function findDeviceByDeviceId (deviceId) {
  try {
    return (await hillo.jsonPost(cloudUrl + '/virtualDevice/search', { deviceId: deviceId })).data?.[0]
  } catch (e) {
    console.log(e)
    return null
  }
}

export function getNgrokUrl (deviceId) {
  return `${location.protocol}//ik${deviceId.padStart(4, '0')}.ngrok.aaden.io`
}

export const cloudUrl = 'https://cloud5.api.aaden.io'
