import { render, staticRenderFns } from "./ResourceNameDisplay.vue?vue&type=template&id=d785ad96&scoped=true&"
import script from "./ResourceNameDisplay.vue?vue&type=script&lang=js&"
export * from "./ResourceNameDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d785ad96",
  null
  
)

export default component.exports