<template>
  <div
      v-if="detailedLog?.length>0"
      class="pa-1 mt-4"
  >
    <v-card
        v-for="{changeLog,dishResource} in detailedLog"
        :key="changeLog.id"
        class="pa-4 mb-1 d-flex align-center"
        elevation="0"
    >
      <div style="width: 96px">
        <div class="text-caption">
          {{ changeLog.createdAt | beautifulTime }}
        </div>
        <v-chip
            :color="changeLog.operation==='Enter'?'success darken-4':'error darken-4'"
            label
            small
        >
          {{ changeLog.operation }}
        </v-chip>
      </div>
      <div>
        <div class="text-body-1 font-weight-bold">
          {{ dishResource.dishResource.name }}
        </div>
        <div class="text-body-2">
          {{ changeLog.note || '没有备注' }}
        </div>
      </div>
      <v-spacer/>

      <div class="mr-4">
        <div class="d-flex">
          <v-icon
              v-if="changeLog.amount<0"
              color="warning"
          >
            mdi-trending-down
          </v-icon>
          <v-icon
              v-if="changeLog.amount>=0"
              color="success"
          >
            mdi-trending-up
          </v-icon>
          <div style="width: 96px;text-align: right">
            {{ changeLog.unitDisplay }}
          </div>
        </div>
        <div class="d-flex text-body-2">
          <v-icon>
            mdi-cash-multiple
          </v-icon>
          <div style="width: 96px;text-align: right">
            {{ changeLog.costLiteral | priceDisplay }}
          </div>
        </div>
      </div>
      <v-icon v-if="changeLog.canceled === 'false'" x-large @click="cancelOperation(changeLog,dishResource)">
        mdi-backup-restore
      </v-icon>
    </v-card>
  </div>
  <div v-else>
    <div
        class="d-flex align-center justify-center flex-column"
        style="height: 400px;width: 100%"
    >
      <v-icon size="64">
        mdi-tea
      </v-icon>
      <div>
        {{ $t('thisCheckHaveNotChange') }}
      </div>
    </div>
  </div>
</template>

<script>

import {sureToReturn} from "@/js/api/api";
import {editResourceCancelStatus, storageChange, storageChangeIncludeBatchNum} from "../../js/api/api";

export default {
  name: "RecordPage",
  props: {
    detailedLog: {},
    editMode: {},
  },
  computed: {},
  data: () => ({
  }),
  methods:{
    async cancelOperation(changeLog,dishResource){
      const res = dishResource.unitSetWithUnit.resourceUnits.pop()
      await sureToReturn(async ()=>{
        if(changeLog.amount > 0){
          await storageChangeIncludeBatchNum(
              dishResource.dishResource.id,
              res.id,
              -changeLog.amount,
              changeLog.priceLiteral,
              '取消入库',
              'false',
              changeLog.batchNum)
          await editResourceCancelStatus(changeLog.id)
        } else {
          await storageChange(
              dishResource.dishResource.id,
              res.id,
              -changeLog.amount,
              changeLog.priceLiteral,
              '取消出库',
              'false')
          await editResourceCancelStatus(changeLog.id)
        }
      })
      this.$emit('reloadPage')
      this.$emit('ok')
    },
  },
}
</script>

<style scoped>

</style>
