<template>
  <v-app>
    <v-app-bar
        app
        dark
        flat
    >
      <template v-if="!searchMode">
        <!--        <v-app-bar-nav-icon @click="showDrawer=true"></v-app-bar-nav-icon>-->
        <v-icon v-if="currentMode!==DisplayMode.First" class="mr-4" @click="changeMode(DisplayMode.First)">
          mdi-keyboard-backspace
        </v-icon>
        <v-app-bar-title>Aaden POS</v-app-bar-title>
        <v-toolbar-items>
          <template v-if="currentMode===DisplayMode.Record">
            <v-btn @click="timeSelectDialog=true">
              <v-icon left>mdi-calendar</v-icon>
              {{ dates }}
            </v-btn>
          </template>
          <v-btn v-if="currentMode===DisplayMode.Normal" @click="showTimeRangePickDialog=true">
            <v-icon left>
              mdi-chart-timeline-variant-shimmer
            </v-icon>
            销量周期
          </v-btn>
          <v-btn v-if="currentMode!==DisplayMode.First" class="ml-4" icon @click="searchMode=true">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar-items>
      </template>
      <template v-else>
        <v-text-field
            v-model="search"
            append-icon="mdi-close"
            autofocus
            hide-details
            placeholder="输入需要搜索的原料名称"
            prepend-inner-icon="mdi-magnify"
            @click="search=''"
            @click:append="searchMode=false;search=''"
        />
      </template>
      <template v-if="currentMode!==DisplayMode.First" #extension>
        <div class="d-flex" style="width: 100%">
          <div
              style="overflow-x: scroll;display: grid;grid-auto-flow: column;grid-gap: 8px;grid-auto-columns: max-content"
          >
            <v-chip
                v-for="tag in tagList"
                :key="tag.id"
                :close="editMode"
                :color="selectedTagIdList.includes(tag.id)?'primary':tag.color"
                label
                light
                @click="tagClick(tag.id)"
                @click:close="tagClick(tag.id)">
              {{ tag.name }}
            </v-chip>
          </div>
          <v-spacer></v-spacer>
          <template v-if="currentMode===DisplayMode.Normal">
            <div class="flex-shrink-0 ml-2">
              <template v-if="editMode">
                <v-chip class="mr-2" label @click="addTag">
                  <v-icon left>mdi-plus-circle</v-icon>
                  新增
                </v-chip>
                <v-chip label @click="editMode=false">
                  <v-icon left>mdi-check</v-icon>
                  完成编辑
                </v-chip>
              </template>
              <v-chip v-else label @click="startEditMode">
                <v-icon left>mdi-pencil</v-icon>
                编辑
              </v-chip>
            </div>
          </template>

        </div>

      </template>
    </v-app-bar>
    <v-main>
      <template v-if="loginStatus">
        <template v-if="currentMode===DisplayMode.Check">
          <check-page :resource="resource"
                      @ok="ok"
                      @return="changeMode(DisplayMode.First)"/>
        </template>
        <template v-else-if="currentMode===DisplayMode.Order">
          <order-page
              :current-time-range="currentTimeRange"
              :resource="resource"
              @ok="ok"
          />
        </template>
        <template v-else-if="currentMode===DisplayMode.Deliver">
          <store-page
              :current-time-range="currentTimeRange"
              :edit-mode="editMode"
              :resource="resource"
              :select-type="currentMode"
              @ok="ok"
          />
        </template>
        <template v-else-if="currentMode===DisplayMode.Store">
          <store-page
              :current-time-range="currentTimeRange"
              :edit-mode="editMode"
              :resource="resource"
              :select-type="currentMode"
              @ok="ok"
          />
        </template>
        <template v-else-if="currentMode===DisplayMode.Record">
          <record-page
              :detailed-log="recordResource"
              @ok="ok"
              @reloadPage="reloadPage"
          />
        </template>
        <template v-else-if="currentMode===DisplayMode.Normal">
          <normal-page
              :current-time-range="currentTimeRange"
              :edit-mode="editMode"
              :resource="resource"
              :tags="tagList"
              @ok="ok"
          />
        </template>
        <template v-else>
          <div class="pa-4">

            <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 12px;">
              <v-card
                  v-for="i in DisplayMode"
                  :key="i"
                  :color="i.color"
                  class="pa-6 d-flex flex-column"
                  dark
                  rounded="xl"
                  elevation="0"
                  height="240"
                  @click="changeMode(i)">
                <div class="text-h4 font-weight-bold">
                  {{ i.name }}
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-icon size="64">{{ i.icon }}</v-icon>
                </div>
              </v-card>
            </div>
            <div class="d-flex flex-column align-center text-h5"
                 style="width: 100%;position: absolute;left: 0;right: 0;bottom: 100px;"
                 @click="signOut">
              <span>登出?</span>
            </div>

          </div>

        </template>
      </template>
      <template v-else>
        <div class="d-flex justify-center align-center" style="height: 80vh">
          <template v-if="!isLoading">
            <o-t-p-keyboard

                :title="title"
                @submit="checkStorePinCode"
            />
          </template>
          <template v-else>
            <div class="d-flex justify-center align-center flex-column">
              <v-progress-circular
                  indeterminate
                  size="64"
              />
            </div>
          </template>
        </div>
      </template>
    </v-main>
    <v-dialog v-model="okDialog" max-width="400px">
      <v-card class="d-flex align-center justify-center flex-column pa-8" rounded>
        <div>
          <v-icon color="success" size="96">mdi-check</v-icon>
        </div>

        <div class="text-body-1">操作成功</div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTimeRangePickDialog" max-width="400px">
      <v-card class="pa-4">
        <div v-if="currentMode===DisplayMode.Normal">
          <div class="text-h4">选择需要查看的销售数据日期范围</div>
        </div>
        <div v-if="currentMode===DisplayMode.Record">
          <div class="text-h4">选择需要查看的出入库记录日期范围</div>
        </div>
        <div class="mt-4"
             style="grid-template-columns: repeat(3,minmax(0,1fr));display: grid;;grid-gap: 8px"
        >
          <v-card v-for="time in timeRange" :key="time"
                  :color="currentTimeRange===time?'primary lighten-1 white--text':'grey lighten-2'"
                  elevation="0"
                  @click="showTimeRangePickDialog=false;currentTimeRange=time">
            <v-responsive :aspect-ratio="1">
              <div class="d-flex align-center
                    justify-center text-h4
                    font-weight-black"
                   style="width: 100%;height: 100%">
                {{ time | dayDisplay }}
              </div>
            </v-responsive>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddTagDialog" max-width="400px">
      <v-card class="pa-4">
        <div class="text-body-1">创建新的标签</div>
        <div class="mt-4">
          <div class="text-caption">标签名称</div>
          <v-text-field v-model="tagName" autofocus hide-details outlined placeholder="输入标签名称"/>
        </div>
        <div class="mt-4">
          <div class="text-caption">选择一个颜色</div>
          <div style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr))">
            <v-card v-for="c in colorList" :key="c" :color="selectedColor===c?'success':''" class="pa-1" elevation="0"
                    @click="selectedColor=c">
              <v-responsive :aspect-ratio="1">
                <v-card :color="c" elevation="0" height="100%" rounded width="100%"/>
              </v-responsive>
            </v-card>
          </div>
        </div>
        <div class="mt-4">
          <v-btn
              :disabled="!tagName"
              color="primary"
              elevation="0"
              large
              width="100%"
              @click="saveTag"
          >
            <v-icon left>mdi-check</v-icon>
            确定
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="timeSelectDialog">
      <v-card class="elevation-0" style="border-radius: 15px">
        <date-range-picker
            @clickConfirm="clickConfirm"
            @close="timeSelectDialog = false"
        />
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="ifLoading"
        max-width="600px"
    >
      <v-card
          class="d-flex align-center justify-center flex-column"
          style="height: 400px;border-radius: 35px"
      >
        <div class="mb-4 text-h3 font-weight-bold">
          稍等片刻
        </div>
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>


import {
  createTag,
  deleteTag,
  getInOutLog,
  getResourceList,
  getTagList,
  getTimeByTimeRangeToShow,
  getTimeSlotByTimeRange,
  timeRangeArr
} from "@/js/api/api"
import CheckPage from "@/components/Fragment/CheckPage"
import OrderPage from "@/components/Fragment/OrderPage"
import NormalPage from "@/components/Fragment/NormalPage"
import {decodeDeviceId, getInfoForDeviceId, Remember} from '@/js/LocalGlobalSettings'
import IKUtils from 'innerken-js-utils'
import StorePage from "@/components/Fragment/StorePage";
import RecordPage from "@/components/Fragment/RecordPage";
import DateRangePicker from "@/components/DateRangePicker";
import {today} from "@/js/DateRepository";
import OTPKeyboard from "@/components/Fragment/OTPKeyboard";

const colorList = ['#FFCDD2', '#F8BBD0', '#E1BEE7',
  '#D1C4E9', '#C5CAE9', '#BBDEFB',
  '#B3E5FC', '#B2EBF2', '#B2DFDB',
  '#C8E6C9', '#DCEDC8', '#F0F4C3',
  '#FFF9C4', '#FFECB3', '#FFE0B2',
  '#FFCCBC', '#D7CCC8', '#CFD8DC']
export const DisplayMode = {
  Normal: {name: '库存状况', color: '#004D40', icon: 'mdi-home'},
  Check: {name: '盘点', color: '#01579B', icon: 'mdi-alert-circle-check-outline'},
  Order: {name: '订货', color: '#E65100', icon: 'mdi-gift-open-outline'},
  Deliver: {name: '出库', color: '#546E7A', icon: 'mdi-arrow-right-bold-outline'},
  Store: {name: '入库', color: '#4E342E', icon: 'mdi-import'},
  Record: {name: '出入库记录', color: '#4527A0', icon: 'mdi-lead-pencil'},
}
export default {
  name: 'HelloWorld',
  components: {
    OTPKeyboard,
    RecordPage,
    StorePage,
    NormalPage,
    OrderPage,
    CheckPage,
    DateRangePicker,
  },
  data: () => ({
    isLoading: false,
    title: 'Aaden库存系统',
    loginStatus: false,
    ifLoading: false,
    today,
    dates: '',
    selectType: null,
    selectTimeRangeRecordList: null,
    timeSelectDialog: null,
    remoteResourceList: [],
    currentMode: DisplayMode.First,
    searchMode: false,
    search: '',
    okDialog: false,
    showDrawer: false,
    currentTimeRange: Remember.timeRange,
    showTimeRangePickDialog: false,
    timeRange: timeRangeArr,
    colorList,
    showAddTagDialog: false,
    selectedColor: colorList[0],
    tagName: '',
    DisplayMode,
    tagList: [],
    editMode: false,
    selectedTagIdList: []
  }),
  computed: {
    recordResource() {
      return this.selectTimeRangeRecordList.filter(it => !this.search ||
          it.dishResource.dishResource.name.toLowerCase().includes(this.search.toLowerCase())).filter(it => {
        return this.selectedTagIdList.length === 0 || it.dishResource.resourceTags.some(t => this.selectedTagIdList.includes(t.id))
      })
    },
    resource() {
      return this.remoteResourceList.filter(it => !this.search ||
          it.dishResource.name.toLowerCase().includes(this.search.toLowerCase())).filter(it => {
        return this.selectedTagIdList.length === 0 || it.resourceTags.some(t => this.selectedTagIdList.includes(t.id))
      })
    }
  },
  watch: {
    currentTimeRange(val) {
      Remember.timeRange = val
    },
  },
  methods: {
    signOut() {
      localStorage.setItem('deviceId', '')
      location.reload()
    },
    async checkStorePinCode(code) {
      this.isLoading = true
      const outId = decodeDeviceId(code).toString()
      const res = await getInfoForDeviceId(outId)
      this.isLoading = false
      if (res.broken) {
        this.title = '请输入正确的密码'
      } else {
        localStorage.setItem('deviceId', outId)
        location.reload()
      }
    },
    async clickConfirm(dateRange, dateRanges) {
      this.timeSelectDialog = false
      this.ifLoading = true
      this.dates = (dateRanges[0] === dateRanges[1] ? dateRanges[0] : dateRanges.join(' ~ '))
      this.selectTimeRangeRecordList = await getInOutLog(dateRange[0], dateRange[1])
      this.ifLoading = false
    },
    startEditMode() {
      this.changeMode(DisplayMode.Normal)
      this.editMode = true
    },
    async tagClick(tagId) {
      if (this.editMode) {
        const res = await IKUtils.showConfirmAsyn("你确定要删除吗？")
        if (res.isConfirmed) {
          this.deleteTag(tagId)
        }
      } else {
        this.toggleTag(tagId)
      }
    },
    toggleTag(tagId) {
      if (this.selectedTagIdList.includes(tagId)) {
        this.selectedTagIdList = this.selectedTagIdList.filter(it => it !== tagId)
      } else {
        this.selectedTagIdList.push(tagId)
      }
    },
    async refreshTagList() {
      this.tagList = await getTagList()
    },
    changeMode(modeName) {
      this.currentMode = modeName
      this.selectType = modeName?.name
      this.editMode = false
      this.reloadPage()
    },
    async ok() {
      this.okDialog = true
      this.remoteResourceList = await getResourceList()
      setTimeout(() => {
        this.okDialog = false
      }, 1000)
    },
    addTag() {
      this.showAddTagDialog = true
      this.selectedColor = this.colorList[0]
      this.tagName = ''
    },
    async saveTag() {
      await createTag(this.tagName, this.selectedColor)
      this.showAddTagDialog = false
      await this.refreshTagList()
    },
    async deleteTag(id) {
      await deleteTag(id)
      await this.refreshTagList()
    },
    async reloadPage() {
      const arr = getTimeSlotByTimeRange(this.currentTimeRange)
      this.selectTimeRangeRecordList = await getInOutLog(arr[0], arr[1])
    },
  },
  async mounted() {
    const res = localStorage.getItem('deviceId')
    if (res) {
      this.loginStatus = true
    }
    this.remoteResourceList = await getResourceList()
    await this.refreshTagList()
    await this.reloadPage()
    const showDateArr = getTimeByTimeRangeToShow(this.currentTimeRange)
    this.dates = (showDateArr[0] === showDateArr[1] ? showDateArr[0] : showDateArr.join(' ~ '))
  },
}
</script>
