<template>
  <div style="position: relative">
    <v-banner color="grey lighten-2" sticky style="top: 56px">
      <div class="d-flex align-center pa-2 text-body-1" style="height: 100%">
        盘点进度💪
        <v-spacer></v-spacer>
        <div class="text-h5 font-weight-black">{{ checkedList.length }}/{{ resource.length }}</div>
      </div>
    </v-banner>
    <div style="display: grid;grid-template-columns: 1fr;grid-gap: 12px">
      <v-card v-for="r in resource"
              :key="r.dishResource.id"
              class="pa-4"
              color="grey lighten-4" elevation="0"
      >
        <div class="d-flex align-center">
          <div class="text-h5 font-weight-bold">
            {{ r.dishResource.name }}
          </div>
          <v-spacer/>
          <div class="mr-2" style="width: 160px">
            <div class="text-caption">现在还有</div>
            <div class="text-h5">
              <div class="pa-2 rounded success darken-2 white--text">{{ resourceDisplay(r) }}</div>
            </div>
          </div>
          <div>
            <div class="text-caption">盘点状态</div>
            <div class="text-h5">
              <template v-if="checkedList.includes(r.dishResource.id)">
                <v-card class="warning darken-2  pa-2 rounded d-flex" dark elevation="0" @click="toggleCheck(r)">
                  <v-icon left>mdi-checkbox-marked</v-icon>
                  <div>
                    已经盘点
                  </div>

                </v-card>
              </template>
              <template v-else>
                <div class="d-flex">
                  <v-card class="pa-2 px-4 d-flex" color="error darken-2 " dark
                          elevation="0" tile
                          @click="startCheck(r)">
                    <v-icon left>mdi-close</v-icon>
                    <div>
                      有误
                    </div>
                  </v-card>
                  <v-card class="pa-2 px-4 d-flex" color="primary darken-2 " dark
                          elevation="0" tile
                          @click="toggleCheck(r)">
                    <v-icon left>mdi-check</v-icon>
                    <div>
                      准确
                    </div>
                  </v-card>
                </div>
              </template>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <v-card
        class="pa-4 d-flex align-center justify-center text-h5" color="primary"
        dark
        style="position: fixed;bottom: 24px;right:0;left:0;width: calc(100vw - 48px);margin: auto;"
        @click="submitCheck"
    >
      完成盘点
    </v-card>

    <v-dialog v-model="showCheckDialog" max-width="700px">
      <v-card v-if="selectedResource">
        <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr))">
          <v-sheet class="pa-4" style="position:relative;">
            <div class="d-flex align-center">
              <div>
                <div class="text-caption">名称</div>
                <div class="text-h4 font-weight-black">
                  {{ selectedResource.dishResource.name }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div>
                <div class="text-caption">当前的记录是</div>
                <div class="text-h4">{{ resourceDisplay(selectedResource) }}</div>
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div>
              <div v-for="log in countLog" :key="log.id" class="py-2 d-flex">
                <v-icon>{{ log.sign > 0 ? 'mdi-plus' : 'mdi-minus' }}</v-icon>

                <v-spacer></v-spacer>
                {{ log.amount }}
                {{ log.unitName }}
              </div>

            </div>
            <div v-if="countLog.length>0" style="width: 100%;
                 position: absolute;bottom: 0;left: 0">
              <div class="text-h4 pa-4 d-flex"
              >
                总计
                <v-spacer></v-spacer>
                {{ amountUnitsDisplay(currentTotalAmount, selectedResource.unitSetWithUnit.resourceUnits) }}
              </div>
            </div>


          </v-sheet>
          <v-sheet dark>
            <div class="pa-2 py-4">
              <v-sheet class="pa-2 text-h5 font-weight-black d-flex align-center"
                       color="grey darken-2">
                <div>
                  {{ amount ? amount : '请输入新的数量' }}
                </div>
                <v-spacer></v-spacer>

                <v-card v-for="unit in activeUnitList"
                        :key="unit.name"
                        :color="((activeUnitId===unit.id)?
                    'grey darken-2':'grey darken-4')"
                        class="text-h5 ml-1 d-flex align-center justify-center flex-shrink-0"
                        elevation="0"
                        height="48px"
                        width="48px"
                        @click="toggleUnit(unit.id)">
                  {{ unit.name }}
                </v-card>
              </v-sheet>

            </div>
            <keyboard :colors="colorList" :keys="keyList" @input="input"/>
          </v-sheet>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="400px">
      <v-card class="pa-4">
        <div class="text-h4">
          请选择原料数量有误的原因....
        </div>
        <div style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 12px">
          <v-card v-for="r in reason" :key="r" tile>
            <v-responsive :aspect-ratio="1">
              <div
                  :key="r"
                  class="d-flex align-center justify-center"
                  style="width: 100%;height: 100%"
              >
                {{ r }}
              </div>
            </v-responsive>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showResultDialog" max-width="500px">
      <v-card class="pa-4">
        <div class="text-body-1 font-weight-bold">盘点成功！🎉</div>
        <div class="text-body-2 mt-1">本次盘点的结果已经保存，短期内请勿重新盘点，以免计算精度降低！</div>
        <div class="mt-6">
          <div class="text-caption">如果需要，请在下方添加一个邮箱地址，盘点的结果可以自动发送到以下</div>
          <v-text-field v-model="email" hide-details outlined placeholder="需要发送的邮件地址（选填）"/>
        </div>
        <div class="mt-4">
          <v-btn v-if="!email" class="mr-2" color="primary" elevation="0" @click="returnToHome">
            <v-icon left>mdi-arrow-left</v-icon>
            回到首页
          </v-btn>
          <v-btn v-else color="primary" elevation="0" @click="sendEmailAndReturnToHome">
            发送并回到首页
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog :just-wait="justWait"/>
  </div>
</template>

<script>
import Keyboard from "@/components/Keyboard"
import {amountUnitsDisplay, getLowestUnitCount} from '@/js/api/api'
import {createCheck, getCheckList, sendCheckEmail, setNewNumber} from "../../js/api/api";
import IKUtils from "innerken-js-utils";
import LoadingDialog from "./LoadingDialog";

const reason = [
  '理由1', '理由2', '理由3', '理由4'
]

const keyList = [
  "7", "8", "9", "C",
  "4", "5", "6", "加",
  "1", "2", "3", "减",
  "清空", "0", ".", "好的"
]

const colorList = [
  "", "", "", "",
  "", "", "", "success darken-2 ",
  "", "", "", "warning darken-2",
  "error darken-2", "", "", "primary darken-2 "
]

let counter = 0
export default {
  name: "CheckPage",
  components: {LoadingDialog, Keyboard},
  props: {
    resource: {}
  },
  computed: {
    activeUnitList () {
      return this.selectedResource.unitSetWithUnit.resourceUnits.filter(it => !this.hideUnitPick || it.id === this.activeUnitId)
    },
    currentTotalAmount () {
      return this.countLog.reduce((sum, i) => {
        return sum + i.realAmount * i.sign
      }, 0)
    }
  },
  data () {
    return {
      justWait: false,
      checkedList: [],
      showCheckDialog: false,
      keyList,
      colorList,
      amount: '',
      selectedResource: null,
      hideUnitPick: true,
      countLog: [],
      activeUnitId: null,

      showResultDialog: false,
      reason,
      email: ''
    }
  },
  methods: {
    async returnToHome () {
      this.$emit('ok')
      this.$emit('return')
    },
    async sendEmailAndReturnToHome () {
      this.justWait = true
      const res = (await getCheckList())[0]
      await sendCheckEmail({
        id: res.id,
        email: this.email,
      })
      this.justWait = false
      IKUtils.toast('发送成功')
      this.$emit('ok')
      this.$emit('return')
    },
    amountUnitsDisplay,
    toggleCheck (resource) {
      if (this.checkedList.includes(resource.dishResource.id)) {
        this.checkedList = this.checkedList.filter(it => it !== resource.dishResource.id)
      } else {
        this.checkedList.push(resource.dishResource.id)
      }
    },
    resourceDisplay (r) {
      const amount = r.overrideAmount ?? r.dishResource.currentCount
      return amountUnitsDisplay(amount, r.unitSetWithUnit.resourceUnits)
    },
    startCheck (resource) {
      this.countLog = []
      this.selectedResource = resource
      this.activeUnitId = this.selectedResource.unitSetWithUnit.resourceUnits[0].id
      this.showCheckDialog = true
    },
    toggleUnit (id) {
      if (this.hideUnitPick) {
        this.hideUnitPick = false
      } else {
        this.activeUnitId = id
        this.hideUnitPick = true
      }
    },
    input (key) {
      let sign = 1
      switch (key) {
        case '加':
        case '减':
          if (!this.amount) {
            return
          }
          sign = key === '加' ? 1 : -1
          this.countLog.push({
            sign,
            amount: this.amount,
            unitId: this.activeUnitId,
            unitName: this.selectedResource.unitSetWithUnit.resourceUnits.find(it => it.id === this.activeUnitId).name,
            id: counter++,
            realAmount: getLowestUnitCount(this.amount, this.activeUnitId, this.selectedResource.unitSetWithUnit.resourceUnits)
          })
          this.amount = ''

          break
        case '好的':
          if (this.amount) {
            this.countLog.push({
              sign,
              amount: this.amount,
              unitId: this.activeUnitId,
              unitName: this.selectedResource.unitSetWithUnit.resourceUnits.find(it => it.id === this.activeUnitId).name,
              id: counter++,
              realAmount: getLowestUnitCount(this.amount, this.activeUnitId, this.selectedResource.unitSetWithUnit.resourceUnits)
            })
          }
          this.amount = ''
          this.showCheckDialog = false
          this.selectedResource.overrideAmount = this.currentTotalAmount
          this.toggleCheck(this.selectedResource)
          break
        case '清空':
          this.countLog = []
          this.amount = ''
          break
        case 'C':
          this.amount = ''
          break
        default:
          this.amount += key
      }
    },
    async submitCheck () {
      this.justWait = true
      const needChanges = this.resource.filter(it => it.overrideAmount !== undefined)
      for (const it of needChanges) {
        await setNewNumber(it.dishResource.id, it.overrideAmount)
      }
      await createCheck()
      this.justWait = false
      this.showResultDialog = true
      this.email = ''
      this.checkedList = []
    }
  }
}
</script>

<style scoped>

</style>
