<template>
  <div class="pa-6">
    <div class="d-flex align-center mb-4">
      <div class="text-h5 font-weight-bold">
        选择日期范围
      </div>
      <v-spacer/>
      <div>
        <v-btn
            icon
            @click="$emit('close')"
        >
          <v-icon large>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="mt-2">
      <div style="display: grid;grid-template-columns: repeat(2,1fr);grid-gap: 8px">
        <div>
          <v-dialog
            ref="dialog"
            v-model="startDateDialog"
            width="290px"
          >
            <template #activator="{ on, attrs }">
              <div class="text-body-1 font-weight-bold mb-2">
                从...
              </div>
              <v-text-field
                v-model="startDate"
                v-bind="attrs"
                v-on="on"
                hide-details
                outlined
                placeholder="从..."
                prepend-inner-icon="mdi-calendar"
                readonly
              />
            </template>
            <v-date-picker
              v-model="startDate"
              :max="endDate"
              @input="startDateDialog=false"
            />
          </v-dialog>
        </div>
        <div>
          <v-dialog
            ref="dialog"
            v-model="endDateDialog"
            width="290px"
          >
            <template #activator="{ on, attrs }">
              <div class="text-body-1 font-weight-bold mb-2">
                到...
              </div>
              <v-text-field
                v-model="endDate"
                v-bind="attrs"
                v-on="on"
                hide-details
                outlined
                placeholder="到..."
                prepend-inner-icon="mdi-calendar"
                readonly
              />
            </template>
            <v-date-picker
              v-model="endDate"
              :max="today"
              :min="startDate"
              @input="endDateDialog=false"
            />
          </v-dialog>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <v-spacer></v-spacer>
    <v-btn class="mt-4 primary" rounded x-large @click="$emit('clickConfirm',dateRange,dateRanges)">
      <span class="font-weight-bold text-h6">确认</span>
    </v-btn>
    </div>
  </div>
</template>

<script>

import {predefinedDateRangeList, today} from '@/js/DateRepository'
import dayjs from "dayjs";

const ktorTimeFormat = 'YYYY-MM-DDTHH:mm:ss'

export default {
    name: 'DateRangePicker',
    props: {
      value: {
        type: Array,
      },
    },
    data: function () {
      return {
        today,
        startDate: null,
        endDate: null,
        startDateDialog: null,
        endDateDialog: null,
        predefinedTimeList: predefinedDateRangeList,
      }
    },
    computed: {
      dateStart(){
        return dayjs(this.startDate).format(ktorTimeFormat)
      },
      dateEnd(){
        return dayjs(this.endDate).add(23, 'hour').add(59, 'minute').format(ktorTimeFormat)
      },
      dateRange: {
        get () {
          return [this.dateStart ?? this.dateEnd ?? today, this.dateEnd ?? this.dateStart ?? today]
        },
        set (val) {
          if (val?.length === 2) {
            [this.dateStart, this.dateEnd] = val
          }
        },
      },
      dateRanges: {
        get () {
          return [this.startDate ?? this.endDate ?? today, this.endDate ?? this.startDate ?? today]
        },
        set (val) {
          if (val?.length === 2) {
            [this.startDate, this.endDate] = val
          }
        },
      },
    },
    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.dateRange = val
        },
      },
      dateRange (val) {
        this.$emit('input', val)
        console.log(val,'val')
      },
    },
  }
</script>

<style scoped>

</style>
